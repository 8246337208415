import { Body, ChildTitle } from "../Common/CommonStyled";

function Youth() {
    return(
        <Body>
            <ChildTitle>유스그룹</ChildTitle>
        </Body>
    );
}

export default Youth;