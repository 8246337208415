import { Body, ChildTitle } from "../Common/CommonStyled";

function Women() {
    return(
        <Body>
            <ChildTitle>여선교회</ChildTitle>
        </Body>
    );
}

export default Women;