import { Body, ChildTitle } from "../Common/CommonStyled";

function Senior() {
    return(
        <Body>
            <ChildTitle>실로암</ChildTitle>
        </Body>
    );
}

export default Senior;