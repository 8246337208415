import { Body, ChildTitle } from "../Common/CommonStyled";

function Men() {
    return(
        <Body>
            <ChildTitle>남선교회</ChildTitle>
        </Body>
    );
}

export default Men;