import { Body, ChildTitle } from "../Common/CommonStyled";

function Multicultural() {
    return(
        <Body>
            <ChildTitle>다문화</ChildTitle>
        </Body>
    );
}

export default Multicultural;