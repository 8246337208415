import { Body, ChildTitle } from "../Common/CommonStyled";

function Children() {
    return(
        <Body>
            <ChildTitle>주일학교</ChildTitle>
        </Body>
    );
}

export default Children;